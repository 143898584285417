import { useQuery } from '@tanstack/react-query'
import { gql } from '@apollo/client'

import { SupportedIndexes } from '../../constants/indexes';
import { useCtxMarketPrice } from '../governance';
import { useIndexPriceLastUpdate } from '../indexes/graph';
import { useSupportedTokenPrice } from '../wallet';
import { SupportedTokens } from '../../constants/tokens';
import { useUniswapV3GraphClient } from '../network';
import { formatUnits } from 'viem';
import { useIncentivesDetails } from '.';


export const useV3PoolData = () => {
  const graphClient =  useUniswapV3GraphClient()

  return useQuery({
    queryKey: ['v3PoolData'],
    enabled: true,
    refetchInterval: 1000 * 60 * 60 * 24,
    queryFn: async () => {
      const query = gql(`
        query PoolDatas {
          poolDayDatas(
            first: 1000,
            orderBy: date,
            orderDirection: desc,
            where: { pool: "0x2b70cea59a418d77265482564610ef4d681d5ad6" }
          ) {
            date
            liquidity
            sqrtPrice
            token0Price
            token1Price
            feesUSD
            close
          },
          pool(id: "0x2b70cea59a418d77265482564610ef4d681d5ad6")
          {
            totalValueLockedUSD
          }
        }
      `);

      const { poolDayDatas, pool } = await graphClient.request(query) as any
      return { poolDayDatas, pool };
    }
  })
}

export const useIncetivesAPY = (index: SupportedIndexes) => { 
  const { data: ctxPriceData } = useCtxMarketPrice()
  const { data: indexPrice } = useIndexPriceLastUpdate(index, 90000)
  const { data: wethPrice } = useSupportedTokenPrice(SupportedTokens.weth)
  const { data: v3PoolData } = useV3PoolData()
  const { data: incentivesDetail } = useIncentivesDetails()

  return useQuery({
    queryKey: ['incentivesApy'],
    enabled: !!ctxPriceData && !!indexPrice && !!wethPrice && !!v3PoolData && !!incentivesDetail,
    queryFn: async () => {
      let dailyCtxBudget = 254.5;
      if (incentivesDetail && incentivesDetail.payouts && incentivesDetail.payouts.length > 0) {
        dailyCtxBudget = parseFloat(formatUnits(incentivesDetail.payouts[0].pool_amount, 18))
      }
      
      const dailyData = v3PoolData?.poolDayDatas.map((data: any) => { 
        const liquidityPrice = parseFloat(formatUnits(BigInt(data.liquidity) * (2n ** 96n) / BigInt(data.sqrtPrice), 18));
        const liquidityToken0Terms = liquidityPrice * data.close;
        const liquidityUsdTerms = liquidityToken0Terms * (indexPrice?.value || 0);

        return {
          date: data.date,
          liquidityPrice,
          liquidityToken0Terms,
          liquidityUsdTerms,
          feesUSD: parseFloat(data.feesUSD),
        }
      })
      const sortedDailyData = dailyData.sort((a: any, b: any) => b.date - a.date)

      if (sortedDailyData.length > 0) {
        const currentLiquidityUsd = parseFloat(v3PoolData?.pool.totalValueLockedUSD || "0");
        const avgDailyFeesUsdAllTime = sortedDailyData.reduce((acc: number, data: any) => acc + data.feesUSD, 0) / sortedDailyData.length;

        // daily ctx budget
        const dailyCtxBudgetUsd = dailyCtxBudget * ctxPriceData.usd;

        // estimate pool APY from feesUSD
        // const poolApyFeesUsd = ((1 + (avgDailyFeesUsdAllTime / currentLiquidityUsd)) ** 365) - 1

        // estimate pool APY from ctx budget
        // const poolApyCtx = ((1 + (dailyCtxBudgetUsd / currentLiquidityUsd)) ** 365) - 1

        // estimate pool APY from feesUSD and ctx budget
        const poolApyFeesCtx = ((1 + ((avgDailyFeesUsdAllTime + dailyCtxBudgetUsd) / currentLiquidityUsd)) ** 365) - 1
        return poolApyFeesCtx;
      }

      return 0;
    }
  })
}
