import { useQuery } from '@tanstack/react-query'
import { gql } from '@apollo/client'
import { formatUnits } from 'viem'
import axios from "axios"

import { useChainId, useIndexesGraphClient } from '../network'
import { GraphDefaultPageSize } from '../../utils/graphUtils'
import { IndexMetadata, SupportedIndexes } from '../../constants/indexes'
import { useSupportedTokenPrice } from '../wallet'
import { SupportedTokens } from '../../constants/tokens'


export const useIndexChartPrices = (index: SupportedIndexes, fromTimestamp: number, toTimestamp?: number) => {
  const chainId = useChainId()
  const graphClient = useIndexesGraphClient()

  return useQuery({
    queryKey: ['indexGraphPrices', chainId, fromTimestamp, toTimestamp],
    enabled: true,
    queryFn: async () => {
      const query = gql(`
        query IndexPrices($fromTimestamp: BigInt!) {
          indexPrices(
            orderBy: blockTimestamp, 
            orderDirection: desc,
            first: ${GraphDefaultPageSize} 
          ) {
            id
            updatedAt
            roundId
            blockTimestamp
            blockNumber
            answer
          }
        }
      `);
      
      const indexMetadata = IndexMetadata[index]
      let prices = new Array<{ time: number, value: number }>();
      const { indexPrices } = await graphClient.request(query, {
        fromTimestamp: fromTimestamp,
      }) as any

      prices = indexPrices.map((price: any) => ({
        time: parseInt(price.blockTimestamp),
        value: parseFloat(formatUnits(price.answer, indexMetadata.decimals)),
      }))

      return {
        prices: prices.reverse(),
      }
    },
  })
}

export const useIndexPriceLastUpdate = (index: SupportedIndexes, refetchInterval: number) => {
  const chainId = useChainId()
  const graphClient = useIndexesGraphClient()

  return useQuery({
    queryKey: ['indexPriceLastUpdate', chainId, index],
    enabled: true,
    refetchInterval,
    queryFn: async () => {
      const query = gql(`
        query IndexPriceLastUpdate {
          indexPrices(orderBy: blockTimestamp, orderDirection: desc, first: 1) {
            id
            updatedAt
            roundId
            blockTimestamp
            blockNumber
            answer
          }
        }
      `);

      const indexMetadata = IndexMetadata[index]  
      const { indexPrices } = await graphClient.request(query) as any

      return {
        time: parseInt(indexPrices[0].blockTimestamp),
        value: parseFloat(formatUnits(indexPrices[0].answer, indexMetadata.decimals)),
      }
    },
  })
}

export const use24hIndexPriceChange = (index: SupportedIndexes, refetchInterval: number) => { 
  const chainId = useChainId()
  const graphClient = useIndexesGraphClient()

  return useQuery({
    queryKey: ['24hIndexPriceChange', chainId, index],
    enabled: true,
    refetchInterval,
    queryFn: async () => {
      const fromTimestamp = Math.floor(Date.now() / 1000) - 86400

      const query = gql(`
        query IndexPrices($fromTimestamp: BigInt!) {
          indexPrices(
            where: { blockTimestamp_gte: ${fromTimestamp} },
            orderBy: blockTimestamp, 
            orderDirection: desc
          ) {
            id
            updatedAt
            roundId
            blockTimestamp
            blockNumber
            answer
          }
        }
      `);

      const indexMetadata = IndexMetadata[index]
      const { indexPrices } = await graphClient.request(query, { fromTimestamp: fromTimestamp, }) as any
      
      let lastPrice = 0n;
      let change = 0;
      if (indexPrices.length > 0) {
        lastPrice = indexPrices[0].answer;
        const prevPrice = parseFloat(formatUnits(indexPrices[indexPrices.length - 1].answer, indexMetadata.decimals))
        change = ((parseFloat(formatUnits(lastPrice, indexMetadata.decimals)) - prevPrice) / prevPrice) * 100
      }

      return {
        lastPrice,
        change,
        postiveChange: change >= 0,
      }
    },
  })
}

export const useIndexMarketPrice = (index: SupportedIndexes) => { 
  const { data: wethPrice } = useSupportedTokenPrice(SupportedTokens.weth)

  return useQuery({
    queryKey: ['indexMarketPrice', index],
    enabled: !!wethPrice,
    refetchInterval: 55000,
    queryFn: async () => {
      const headers = { 'x-cg-demo-api-key': process.env.REACT_APP_COINGECKO_API_KEY };
      const params = {
        ids: index,
        vs_currencies: 'usd',
        include_24hr_change: true,
        include_last_updated_at: true,
      }

      const priceData = await axios.get("https://api.coingecko.com/api/v3/simple/price", { headers, params })
      if (priceData.status === 200) { 
        return priceData.data[index];
      }
    },
  })
}
