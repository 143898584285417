import { useEffect, useState } from 'react'
import {
  HermesClient,
  isSupportedChain as isPerennialSupportedChain,
  SupportedChainId as PerennialSupportedChainId,
  isTestnet,
  PriceUpdate,
  nowSeconds,
} from '@perennial/sdk'
import { useQuery } from '@tanstack/react-query'

import EventEmitter from 'events'
import { GraphQLClient } from 'graphql-request'
import { PublicClient, createPublicClient, webSocket } from 'viem'
import { arbitrum, base, mainnet } from 'viem/chains'
// eslint-disable-next-line no-restricted-imports
import { useAccount, useSwitchChain, useAccount as useWagmiAccount } from 'wagmi'

import {
  FuulGraphUrl,
  PythMainnetUrl,
  PythTestnetUrl,
  PerpetualsGraphUrls,
  rpcUrls,
  SupportedChainIdType,
  V1GraphUrls,
  isSupportedChain,
  IndexesGraphUrls,
} from '../constants/network'
import { useCryptexProductsContext } from '../contexts'
import { usePerennialSDKContext } from '../contexts/perennialSdkContext'


export const useAddress = () => {
  const { address: wagmiAddress } = useWagmiAccount()

  const [addressInfo, setAddressInfo] = useState<{ address: `0x${string}` | undefined; overriding: boolean }>({
    address: undefined,
    overriding: false,
  })

  useEffect(() => {
    setAddressInfo({ address: wagmiAddress, overriding: false })
  }, [wagmiAddress])

  return addressInfo
}

export const useChainId = () => { 
  let { chain } = useAccount()
  const products = useCryptexProductsContext()
  
  chain = chain ?? products.getDefaultChain()

  if (chain === undefined || !products.isProductSupportedChain(chain.id)) {
    return products.getDefaultChain().id as SupportedChainIdType;
  }

  return chain.id as SupportedChainIdType;
}

export const useCurrentChain = () => { 
  let { chain } = useAccount()
  const products = useCryptexProductsContext()
  
  chain = chain ?? products.getDefaultChain()

  if (chain === undefined || !products.isProductSupportedChain(chain.id)) {
    return products.getDefaultChain();
  }

  return chain;
}

export const usePerpetualsChainId = () => {
  let { chain } = useAccount();
  chain = chain ?? arbitrum

  if (chain === undefined || arbitrum.id !== chain.id ) return arbitrum.id
  
  return chain.id as PerennialSupportedChainId
}

export const useOnSupportedChain = () => { 
  let { chain } = useAccount();
  if (chain) {
    return { isUsingSupportedChain: isSupportedChain(chain.id) }
  }
  return { isUsingSupportedChain: true }
}

export const useOnPerpetualsSupportedChain = () => {
  let { chain } = useAccount();
  if (chain) {
    return { isUsingSupportedChain: isPerennialSupportedChain(chain.id) }
  }
  return { isUsingSupportedChain: true }
}


const viemWsClients = new Map<PerennialSupportedChainId, PublicClient>()
// We need to create a WS public client directly instead of using Wagmi's hooks because the wagmi hook
// returns a Fallback provider which does not support eth_subscribe
export const useViemWsClient = () => {
  const chainId = usePerpetualsChainId()
  const providerUrl = useRPCProviderUrl()

  if (!viemWsClients.has(chainId)) {
    viemWsClients.set(chainId, createPublicClient({ transport: webSocket(providerUrl.replace('https://', 'wss://')) }))
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return viemWsClients.get(chainId)!
}

export const useRPCProviderUrl = (): string => {
  const products = useCryptexProductsContext();
  const chainId = products.getDefaultChain().id as SupportedChainIdType;

  return rpcUrls[chainId]
}

const perpetualsGraphClients = new Map<PerennialSupportedChainId, GraphQLClient>()
export const useGraphClient = () => {
  const chainId = usePerpetualsChainId()

  if (!perpetualsGraphClients.has(chainId)) perpetualsGraphClients.set(chainId, new GraphQLClient(PerpetualsGraphUrls[chainId]))

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return perpetualsGraphClients.get(chainId)!
}

const indexesGraphClients = new Map<SupportedChainIdType, GraphQLClient>()
export const useIndexesGraphClient = () => {
  const chainId = useChainId()

  if (!indexesGraphClients.has(chainId)) indexesGraphClients.set(chainId, new GraphQLClient(IndexesGraphUrls[chainId]))

  return indexesGraphClients.get(chainId)!
}

const v1GraphClients = new Map<SupportedChainIdType, GraphQLClient>()
export const useV1GraphClient = () => { 
  const chainId = useChainId()

  if (!v1GraphClients.has(chainId)) v1GraphClients.set(chainId, new GraphQLClient(V1GraphUrls[chainId]))

  return v1GraphClients.get(chainId)!
}

export const useFuulGraphClient = () => { 
  return new GraphQLClient(FuulGraphUrl)
}

export const useUniswapV3GraphClient = () => {
  const apiKey = process.env.REACT_APP_THE_GRAPH_API_KEY;
  const url = `https://gateway.thegraph.com/api/${apiKey}/subgraphs/id/43Hwfi3dJSoGpyas9VwNoDAv55yjgGrPpNSmbQZArzMG`;
  return new GraphQLClient(url)
}

export const useUniswapPricesGraphClient = () => {
  const url = `https://interface.gateway.uniswap.org/v1/graphql`;
  return new GraphQLClient(url)
}

const pythClients = {
  mainnet: new HermesClient(PythMainnetUrl),
  testnet: new HermesClient(PythTestnetUrl),
}

export const usePyth = () => {
  const chainId = usePerpetualsChainId()
  return isTestnet(chainId) ? pythClients.testnet : pythClients.mainnet
}

const pythSubscriptions = new Map<string, EventEmitter>()
export const usePythSubscription = (feedIds: string[]) => {
  const pyth = usePyth()
  const key = feedIds.sort().join(',')
  if (!pythSubscriptions.has(key)) {
    const emitter = new EventEmitter()
    pythSubscriptions.set(key, emitter)

    // Error inside that function
    const stream = pyth.getPriceUpdatesStream(feedIds, { parsed: true })
    stream.then((eventSource) => {
      eventSource.onmessage = ({ data }: { data: string }) => {
        emitter.emit('updates', JSON.parse(data) as PriceUpdate)
      }
      eventSource.onerror = (error: any) => {
        eventSource.close()
        pythSubscriptions.delete(key)
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return pythSubscriptions.get(key)!
}

export function useRpcHealthCheck() {
  const sdk = usePerennialSDKContext()
  const chainId = usePerpetualsChainId()

  return useQuery({
    queryKey: ['rpcHealthCheck', chainId],
    enabled: !!chainId,
    staleTime: 1000 * 60,
    queryFn: async () => {
      let latestBlock
      try {
        latestBlock = await sdk.publicClient.getBlock({ blockTag: 'latest' })
      } catch (e) {
        console.error(e)
      }
      const currentTime = BigInt(nowSeconds())
      const isNodeLive = latestBlock && currentTime - latestBlock.timestamp < 60n
      return isNodeLive
    },
  })
}

export const useChainActions = () => {
  let { chain } = useAccount()
  const { switchChainAsync, switchChain } = useSwitchChain();
  const { isIndexes, isGovernance, isSpot, isProductSupportedChain } = useCryptexProductsContext();

  const onSwitchChain = async () => { 
    if (chain && !isSpot() && !isProductSupportedChain(chain?.id)) {
      const toChainId =
        isIndexes() ? base.id : (isGovernance() ? mainnet.id : arbitrum.id);

      try {
        await switchChainAsync({ chainId: toChainId })
        return true;
      } catch (error) {
        return false;
      }
    }
    return true;
  }

  const onSwitchChain2 = () => { 
    if (chain && !isSpot() && !isProductSupportedChain(chain?.id)) {
      const toChainId =
        isIndexes() ? base.id : (isGovernance() ? mainnet.id : arbitrum.id);

      try {
        switchChain({ chainId: toChainId })
        return true;
      } catch (error) {
        return false;
      }
    }
    return true;
  }

  return {
    onSwitchChain,
    onSwitchChain2
  }
}
