import React, { useMemo } from "react";
import { formatBig18USDPrice } from "@perennial/sdk";

import { Stack } from "react-bootstrap";

import { use24hIndexPriceChange, useIndexMarketPrice } from "../../../hooks/indexes/graph";
import { useIndexVaultContext } from "../../../contexts/indexVaultContext";
import { CustomTooltip } from "../../common";


export const IndexPrices = () => {
  const { selectedIndex } = useIndexVaultContext()
  const { data: prices } = use24hIndexPriceChange(selectedIndex, 60000)
  const { data: marketPrice } = useIndexMarketPrice(selectedIndex)

  const { lastPrice, change, postiveChange } = useMemo(() => { 
    if (!prices) return { lastPrice: 0n, change: 0, postiveChange: true }
    return {
      ...prices
    }
  }, [prices])

  return (
    <Stack
      direction="horizontal"
      className="align-items-center justify-content-center py-1 px-0 index-prices-container"
      gap={2}
    >
      <Stack direction="horizontal" className="border-box px-2 py-1" gap={3}>
        <Stack direction="horizontal" gap={1}>
          <h6 className="text-muted">Oracle Price:</h6>
          <h6 className="text-green">{formatBig18USDPrice(lastPrice)}</h6>
        </Stack>
        <Stack direction="horizontal" gap={1}>
          <h6 className="text-muted">24h Price:</h6>
          <h6 className={`${postiveChange ? "text-green" : "text-red"}`}>{change.toFixed(1)}%</h6>
        </Stack>
      </Stack>
      <Stack direction="horizontal" className="border-box px-2 py-1" gap={3}>
        <Stack direction="horizontal" gap={1}>
          <CustomTooltip
            id="tt-market-price"
            msg={
              <span>
                Price data by <a href="https://www.coingecko.com/en/coins/tcap-2" target="_blank" rel="noreferrer">CoinGecko</a>
              </span>
            }
            showIcon={true}
            iconOnLeft={true}
            hideDelay={500}
            iconSize={12}
            iconColor="#9795b0"
          >
            <h6 className="text-muted ps-1">Market Price:</h6>
          </CustomTooltip>  
          <h6 className="text-green">
            {marketPrice ? `$${marketPrice.usd}` : '-'}
          </h6>
        </Stack>
        <Stack direction="horizontal" gap={1}>
          <h6 className="text-muted">24h Price:</h6>
          <h6 className={`${marketPrice && marketPrice.usd_24h_change >= 0 ? "text-green" : "text-red"}`}>
            {marketPrice ? `${marketPrice.usd_24h_change.toFixed(1)}%` : '-'}
          </h6>
        </Stack>
      </Stack>
    </Stack>  
  )    
}