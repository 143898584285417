import { useQuery } from '@tanstack/react-query'
import { gql } from '@apollo/client'

import { useAddress, useFuulGraphClient } from '../network'
import { CtxTokenAddresses } from '../../constants/contracts'
import { base } from 'viem/chains'
import axios from 'axios'
// import { GraphDefaultPageSize } from '../../utils/graphUtils'


export const useIncentives = () => {
  const graphClient = useFuulGraphClient()

  return useQuery({
    queryKey: ['incentives'],
    enabled: true,
    queryFn: async () => {
      const query = gql(`
        query Incentives {
          projects (where: { projectId: "20" }) {
            projectId
            projectInfoURI
            clientFeeCollector,
            budgets {
              currency,
              amount
            }
          }
        }
      `);
      const { projects } = await graphClient.request(query) as any

      return {
        incentives: projects,
      }
    },
  })
}

export const useUserRewards = () => {
  const address = useAddress()
  const graphClient = useFuulGraphClient()

  return useQuery({
    queryKey: ['userRewards', address.address],
    enabled: address.address !== undefined,
    queryFn: async () => {
      const query = gql(`
        query UserRewards($address: String!, $currency: String!) {
          userBalances (where: { owner_contains_nocase: $address, currency_contains: $currency }) {
            claimed
            availableToClaim
          }
        }
      `);
      const { userBalances } = await graphClient.request(query, {
        address: address.address,
        currency: CtxTokenAddresses[base.id],
      }) as any

      return {
        userRewards: userBalances,
      }
    },
  })
}

export const useIncentivesDetails = () => { 
  const fuulApiUrl = "https://api.fuul.xyz/api/explorer/v1/"

  return useQuery({
    queryKey: ['incentivesDetails'],
    enabled: true,
    queryFn: async () => {
      const params = new URLSearchParams({
        type: 'incentive',
        project_id: 'a76716eb-f145-4b48-beb5-305c67038652',
      })
      const response = await axios.get(`${fuulApiUrl}details`, {
        params,
      })

      if (response.status === 200) {
        console.log(response.data)
        return response.data;
      }
      return undefined;
    },
  })
}